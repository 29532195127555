<template>
  <v-container>
    <v-card>
      <v-data-table
        :headers="headers"
        :items="users.data"
        :options.sync="tableOptions"
        :loading="loading"
        class="elevation-1"
        :footer-props="{
          'items-per-page-options': tableOptions.rowsPerPageItems,
        }"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip :key="index" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                class="mr-2"
                v-bind="attrs"
                v-on="on"
                @click="goToProfile(item.id)"
              >
                mdi-eye
              </v-icon>
            </template>
            View
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>

    <user-form
      :open="userFormOpen"
      user-type="professional"
      @close="userModalClose"
    />
  </v-container>
</template>
<script>
import userForm from "@/components/userForm";
import {
  getUsers,
  getUserEarning,
  getInvitedUsersCount,
} from "@/services/users";
import DateFilter from "@/components/dateFilter";

export default {
  components: { userForm, DateFilter },
  data() {
    return {
      desserts: [],
      headers: [
        {
          text: "Code",
          align: "start",
          sortable: false,
          value: "reference_code",
        },
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "first_name",
        },
        {
          text: "Email",
          align: "start",
          sortable: false,
          value: "email",
        },
        {
          text: "Total Revenue",
          align: "start",
          sortable: false,
          value: "user_revenue",
        },{
          text: "Commisson",
          align: "start",
          sortable: false,
          value: "user_commission",
        },{
          text: "Earning",
          align: "start",
          sortable: false,
          value: "user_earning",
        },{
          text: "Refral Bouns",
          align: "start",
          sortable: false,
          value: "invited_user_earning",
        },
        {
          text: "Invited",
          align: "start",
          sortable: false,
          value: "invited",
        },
        {
          text: "Action",
          align: "start",
          sortable: false,
          value: "actions",
        },
      ],
      users: {
        data: [],
        actions: ["block", "view"],
      },
      tableOptions: {
        page: 1,
        itemsPerPage: 10,
        totalItems: 0,
        rowsPerPageItems: [10, 50, 100, 200],
      },
      loading: true,
    };
  },
  watch: {
    tableOptions: {
      handler() {
        this.loading = true;
        const _self = this;
        setTimeout(function () {
          _self.getUsersData();
        }, 500);
      },
      deep: true,
    },
  },
  async mounted() {
    this.getUsersData();
  },
  methods: {
    async getUsersData() {
      this.loading = true;
      const params = {
        status: this.usersStatus === "year" ? null : this.usersStatus,
        search: this.searchKeyword,
        limit: this.tableOptions.itemsPerPage,
        page: this.tableOptions.page,
        type: "professional",
        // date: this.dateFilter?.join(","),
      };
      getUsers(params).then((res) => {
        res.data.forEach(async (ele) => {
          await getInvitedUsersCount(ele.id).then((res) => {
            const row = {
              id: ele.id,
              first_name: ele.first_name + " " + ele.last_name,
              reference_code: ele.reference_code,
              email: ele.email,
              invited: res.data.result,
            };
            const userStatsParams = {
              status: "year",
              id: ele.id,
            };
            getUserEarning(userStatsParams).then((res) => {
              this.desserts.push({...row,...res.data});
            });
            
          })
        });

        this.users.data = this.desserts;
        // this.loading = false;
      });
    },
    goToProfile(id) {
      this.$router.push({ name: "Invited Customer", params: { id: id } });
    },
    
   
  },
};
</script>
