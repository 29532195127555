<template>
  <v-row justify="end">
    <v-dialog v-model="open" max-width="600px" @click:outside="$emit('close')">
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ userType === 'driver' ? 'Create Driver' : 'Create User' }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-text-field v-model="user.first_name" label="First name*" hint="First Name of user" persistent-hint
                  required />
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <v-text-field v-model="user.last_name" label="Last name*" hint="Last Name of user" persistent-hint
                  required />
              </v-col>

              <v-col cols="12">
                <v-text-field v-model="user.email" label="Email*" hint="Email of user" required />
              </v-col>

              <v-col cols="12">
                <v-text-field v-model="user.phone" label="Phone*" hint="Phone number of user" placeholder="+441234567890"
                  required />
              </v-col>

              <v-col cols="12">
                <v-text-field v-model="user.password" label="Password*" hint="Password for authentication"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" :type="showPassword ? 'text' : 'password'"
                  @click:append="showPassword = !showPassword" required />
              </v-col>

              <!-- Additional fields specific to drivers -->
              <v-col cols="12">
                <v-checkbox v-model="user.is_internal" label="Is Internal (Driver only)" />
              </v-col>
            </v-row>
            <v-alert v-if="alert.show" border="left" dense text :type="alert.type">
              {{ alert.text }}
            </v-alert>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="blue darken-1" text @click="$emit('close')">Close</v-btn>
          <v-btn color="blue darken-1" text @click="saveUser">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { createUser } from '@/services/users';
import { createDriver } from '@/services/admin';

export default {
  name: 'UserForm',
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    userType: {
      type: String,
      required: true,
    },
    prefilledData: {
      type: Object,
      required: false,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      search: '',
      dialog: false,
      user: {
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        password: '',
        is_internal: false,
      },
      alert: {
        show: false,
        text: '',
        type: 'success',
      },
      showPassword: false,
    };
  },
  mounted() {
    if (Object.entries(this.prefilledData).length !== 0) {
      this.user = JSON.parse(JSON.stringify(this.prefilledData));
    }
  },
  methods: {
    saveUser() {
      const userData = {
        firstName: this.user.first_name,
        surName: this.user.last_name,
        phone: this.user.phone,
        email: this.user.email,
        password: this.user.password,
      };

      if (this.userType === 'driver') {
        userData.is_internal = this.user.is_internal;
        // Add more fields specific to drivers as needed
      }

      if (Object.entries(this.prefilledData).length === 0) {
        userData.type = this.userType;
        userData.status = 'active';
        userData.login_type = 'manual';
      }

      const createService = this.userType === 'driver' ? createDriver : createUser;

      createService(userData)
        .then((res) => {
          // this.alert.show = true;
          // this.alert.type = 'success';
          // this.alert.text = 'User Created!';
          this.$emit('close');

          // Clear fields on success
          this.user = {
            first_name: '',
            last_name: '',
            email: '',
            phone: '',
            password: '',
            is_internal: false,
          };
        })
        .catch((err) => {
          console.log(err.response.data.errors);

          // Handle errors for missing fields
          if (err.response.data.errors) {
            const errorMessages = err.response.data.errors.map((error) => {
              return `${error.msg} (${error.param})`;
            });
            this.alert.show = true;
            this.alert.type = 'error';
            this.alert.text = `Validation Error: ${errorMessages.join(', ')}`;
          } else {
            this.alert.show = true;
            this.alert.type = 'error';
            this.alert.text = err.response.data.error.toString();
          }
        });
    },
  },
};
</script>
